<script setup>
import { ref, computed } from 'vue'
import * as routes from '@/utils/routes'
import Logo from '@/Shared/Logo.vue'
import Icon from '@/Shared/Icon.vue'
import { Inertia } from '@inertiajs/inertia'
import { Link, usePage } from '@inertiajs/inertia-vue3'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close'])

const url = ref('/')
const user = computed(() => usePage().props.value.auth.user)

Inertia.on('navigate', (event) => {
  url.value = event.detail.page.url.substr(1)
})

function isUrl(...urls) {
  if (urls[0] === '') {
    return props.url === ''
  }
  return urls.filter(uri => url.value.startsWith(uri)).length
}
</script>

<template>
  <section
    :class="open ? 'translate-x-0 ease-out duration-200' : '-translate-x-full ease-in duration-200'"
    class="fixed inset-y-0 left-0 z-30 flex flex-col items-center w-full overflow-y-auto lg:z-0 bg-hartblue lg:w-32 lg:static lg:inset-auto lg:translate-x-0"
  >
    <div class="relative w-full px-5 lg:px-0">
      <Link
        :href="routes.root()"
        class="hidden mt-8 lg:block"
      >
        <Logo
          class="w-16 mx-auto"
          version="desktop"
        />
      </Link>

      <div class="block py-4 border-b border-white lg:hidden">
        <Logo
          class="h-8 mx-auto"
          version="mobile"
        />
        <button
          class="absolute inset-y-0 right-0 px-5 text-white focus:outline-none"
          @click="emit('close')"
        >
          <Icon
            name="close"
            class="w-4 h-4 ml-auto fill-current"
          />
        </button>
      </div>
    </div>

    <nav class="flex flex-col justify-between w-full h-full pt-6 pb-2 lg:pt-8 lg:pb-8">
      <ul>
        <li>
          <Link
            :href="routes.appointments()"
            class="flex items-center px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('appointments') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="book"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">Appointments</span>
          </Link>
        </li>

        <li>
          <Link
            :href="routes.clients()"
            class="flex items-center px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('clients') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="users"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">Clients</span>
          </Link>
        </li>
        <li>
          <Link
            :href="routes.leads()"
            class="flex items-center px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('leads') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="add-user"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">Leads</span>
          </Link>
        </li>
        <li>
          <Link
            :href="routes.my_info()"
            class="flex items-center px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('my/info') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="info"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">My Info</span>
          </Link>
        </li>
        <li>
          <Link
            :href="routes.search()"
            class="flex items-center px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('search') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="search"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">Search</span>
          </Link>
        </li>
        <li v-if="user.is_admin">
          <Link
            :href="routes.users()"
            class="flex items-center px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('users') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="agent-user"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">Users</span>
          </Link>
        </li>
      </ul>
      <ul>
        <li>
          <Link
            method="delete"
            as="button"
            :href="routes.logout()"
            class="flex items-center w-full px-10 py-6 lg:px-0 lg:block group hover:bg-hartpurple"
            :class="{ active: isUrl('logout') }"
            @click="emit('close')"
          >
            <div class="w-6 h-6 mx-0 lg:mx-auto">
              <Icon
                name="exit"
                class="text-white fill-current group-hover:text-hartgreen"
              />
            </div>
            <span class="block pl-6 text-xl font-semibold text-center text-white lg:pl-0 lg:text-sm">Logout</span>
          </Link>
        </li>
      </ul>
    </nav>
    <p class="mb-1 text-xs text-white" aria-label="App version">
      v1.3.3
    </p>
  </section>
</template>
